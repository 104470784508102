import React from 'react';
import '../styles/TextPositionControl.css';

const TextPositionControl = ({ onMove }) => {
  const handleStart = (direction) => {
    const interval = setInterval(() => onMove(direction), 50);

    const handleEnd = () => {
      clearInterval(interval);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
      document.removeEventListener('touchcancel', handleEnd);
    };

    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchend', handleEnd);
    document.addEventListener('touchcancel', handleEnd);
  };

  return (
    <div className="text-position-control">
      <button
        onMouseDown={() => handleStart('up')}
        onTouchStart={() => handleStart('up')}
        className="control-button up"
      >
        ↑
      </button>
      <div className="horizontal-controls">
        <button
          onMouseDown={() => handleStart('left')}
          onTouchStart={() => handleStart('left')}
          className="control-button left"
        >
          ←
        </button>
        <button
          onMouseDown={() => handleStart('right')}
          onTouchStart={() => handleStart('right')}
          className="control-button right"
        >
          →
        </button>
      </div>
      <button
        onMouseDown={() => handleStart('down')}
        onTouchStart={() => handleStart('down')}
        className="control-button down"
      >
        ↓
      </button>
    </div>
  );
};

export default TextPositionControl;
