import React, { useState } from 'react';
import '../styles/FAQ.css';

const FAQ = () => {
    const faqData = [
      {
        question: 'What types of images can I upload for my custom keychain?',
        answer: 'You can upload JPEG and PNG files. For the best results, we recommend high-resolution images where the subject is clearly visible.',
      },
      {
        question: 'What makes an image suitable for creating a custom keychain?',
        answer: 'Ideal images are high quality, well-lit, and focus on a single subject. The background should be simple or contrast strongly with the subject to improve the effectiveness of our automatic background removal tool.',
      },
      {
        question: 'Can you provide examples of images that work well and those that don\'t?',
        answer: (
          <div>
            <p>Certainly! Here are some guidelines:</p>
            <p><strong>Good images:</strong></p>
            <ul>
              <li>Clear, focused subjects.</li>
              <li>Minimal background clutter.</li>
              <li>Good lighting without harsh shadows or overexposure.</li>
            </ul>
            <p><strong>Poor images:</strong></p>
            <ul>
              <li>Low resolution or blurry images.</li>
              <li>Subjects that blend into the background.</li>
              <li>Overly busy backgrounds that make it difficult to distinguish the subject.</li>
            </ul>
          </div>
        ),
      },
      {
        question: 'What should I do if the background is not removed properly?',
        answer: 'If the automatic background removal doesn\'t meet your expectations, you can reject the result and upload a different image. Reviewing our image guidelines can help you choose a more suitable photo.',
      },
      {
        question: 'How do I place an order?',
        answer: (
          <p>
            Start by visiting our <a href="/upload">design page</a> where you can upload your image using our intuitive tool. After uploading, you can customize your keychain by choosing contrast settings and keychain attachment directions. Review your design and, once satisfied, proceed securely to checkout.
          </p>
        ),
      },
      {
        question: 'What payment methods are accepted?',
        answer: 'We accept various payment methods, including credit card and iDEAL.',
      },
      {
        question: 'Is my payment information secure?',
        answer: 'Yes, the security of your payment is our highest priority. All transactions are processed through Stripe, which uses the latest security protocols to protect your data. We do not store your payment details on our site.',
      },
      {
        question: 'How long will it take to receive my custom keychain?',
        answer: 'As each keychain is made to order, please allow up to 2 weeks for us to create and ship your product. We appreciate your patience while we craft your personalized item.',
      },
      {
        question: 'Can I track my order once it\'s shipped?',
        answer: 'Absolutely! Once your order is shipped, you\'ll receive a tracking number via email. Use this number to follow your package\'s journey to your doorstep.',
      },
      {
        question: 'What is your return policy?',
        answer: 'Since our keychains are custom-made, we cannot accept returns. Each keychain is specially crafted for you, so please review your design carefully before ordering.',
      },
      {
        question: 'What should I do if I receive a defective product?',
        answer: (
          <p>
            We strive for perfection, but if something goes wrong, please email us at <a href="mailto:info@engravemyride.com">info@engravemyride.com</a> with details and photos of the defect. We will assess the issue and work quickly towards a solution.
          </p>
        ),
      },
      {
        question: 'What should I do if I have trouble using the website?',
        answer: 'If you encounter any issues, don\'t hesitate to contact us at info@engravemyride.com. We\'re here to help and ensure your experience is smooth and enjoyable.',
      },
      {
        question: 'How can I get further assistance if my question isn\'t answered here?',
        answer: 'For further questions or additional help, please contact us directly through our support email: info@engravemyride.com. We are dedicated to providing you with the assistance you need.',
      },
    ];
  
    return (
      <div className="faq-container">
        <div className="hero">
          <h1>Frequently Asked Questions</h1>
          <h2>Everything you need to know about Engrave My Ride</h2>
        </div>
  
        <section className="faq-list">
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </section>
  
        <section className="contact-info">
          <h3>Still have questions?</h3>
          <p>We're here to help! Contact our customer service:</p>
          <p>
            Email: <a href="mailto:info@engravemyride.com" className="contact-link">info@engravemyride.com</a>
          </p>
          <a href="/contact" className="cta-button">
            Contact Us
          </a>
        </section>
      </div>
    );
  };

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen);
          }
        }}
        role="button"
        tabIndex="0"
        aria-expanded={isOpen}
      >
        <h3>{question}</h3>
        <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && (
        <div className="faq-answer" aria-hidden={!isOpen}>
          {typeof answer === 'string' ? <p>{answer}</p> : answer}
        </div>
      )}
    </div>
  );
};

export default FAQ;
