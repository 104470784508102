import React from 'react';
import '../styles/Terms.css';
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div className="terms">
      <section className="hero">
        <h1>Terms and Conditions</h1>
        <h2>Welcome to EngraveMyRide</h2>
      </section>

      <section className="content">
        <h3>1. Introduction</h3>
        <p>
          Welcome to Engrave My Ride ("we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of our website, services, and products (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms.
        </p>

        <h3>2. Acceptance of Terms</h3>
        <p>
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree with these Terms, you must not use our Services.
        </p>

        <h3>3. Eligibility</h3>
        <p>
          You must be at least 18 years old or the age of majority in your jurisdiction to use our Services. By using our Services, you represent and warrant that you meet the eligibility requirements.
        </p>

        <h3>4. Account Registration</h3>
        <p>
          4.1. You may need to create an account to access certain features of our Services.<br />
          4.2. You are responsible for maintaining the confidentiality of your account information and password.<br />
          4.3. You agree to provide accurate and complete information during registration and to update your information as necessary.
        </p>

        <h3>5. Ordering Process</h3>
        <p>
          5.1. To place an order, follow the instructions on our website to customize your product, upload images, and complete the checkout process.<br />
          5.2. All orders are subject to acceptance by us. We reserve the right to refuse or cancel any order for any reason.
        </p>

        <h3>6. Uploading Images</h3>
        <p>
          6.1. By uploading images, you represent and warrant that:<br />
          a. You own or have the necessary rights and permissions to use the images.<br />
          b. The images do not infringe on any third-party intellectual property rights.<br />
          c. The images do not contain prohibited content, including but not limited to nudity, violence, or illegal activities.<br />
          6.2. You retain ownership of any images you upload but grant us a license to use them as described in Section 7.
        </p>

        <h3>7. User Content and Intellectual Property Rights</h3>
        <p>
          7.1. License Grant: By submitting or uploading content ("User Content"), you grant us a non-exclusive, royalty-free, worldwide, sublicensable, and transferable license to use, reproduce, modify, adapt, publish, and display such content solely for the purpose of providing and promoting our Services.<br />
          7.2. Marketing Consent: With your explicit consent, we may use your User Content for marketing and promotional purposes, such as showcasing your customized products on our website or social media channels.<br />
          7.3. Withdrawal of Consent: You may withdraw your consent for us to use your User Content for marketing at any time by contacting us at [email address].
        </p>

        <h3>8. Prohibited Content</h3>
        <p>
          You agree not to upload or submit any content that:<br />
          8.1. Infringes on any third-party intellectual property rights.<br />
          8.2. Contains offensive, defamatory, or illegal material.<br />
          8.3. Promotes violence, discrimination, or illegal activities.
        </p>

        <h3>9. Payment Terms</h3>
        <p>
          9.1. Prices are listed on our website and are subject to change without notice.<br />
          9.2. Payment must be made in full at the time of order placement.<br />
          9.3. We accept payment methods as indicated during the checkout process.
        </p>

        <h3>10. Shipping and Delivery</h3>
        <p>
          10.1. Estimated delivery times are provided during checkout but are not guaranteed.<br />
          10.2. We are not responsible for delays caused by shipping carriers or customs clearance.
        </p>

        <h3>11. Returns and Refunds</h3>
        <p>
          11.1. Due to the customized nature of our products, all sales are final.<br />
          11.2. If you receive a defective or incorrect product, contact us within [number] days for assistance.
        </p>

        <h3>12. Intellectual Property Rights</h3>
        <p>
          12.1. All content on our website, including text, graphics, logos, and images (excluding User Content), is our property or the property of our licensors and is protected by intellectual property laws.<br />
          12.2. You may not use, reproduce, or distribute any content from our Services without our express written permission.
        </p>

        <h3>13. Disclaimer of Warranties</h3>
        <p>
          Our Services are provided "as is" without warranties of any kind, either express or implied. We do not warrant that the Services will be uninterrupted or error-free.
        </p>

        <h3>14. Limitation of Liability</h3>
        <p>
          To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of our Services.
        </p>

        <h3>15. Indemnification</h3>
        <p>
          You agree to indemnify and hold us harmless from any claims, losses, liabilities, damages, expenses, and costs (including legal fees) arising from your use of the Services or violation of these Terms.
        </p>

        <h3>16. Privacy Policy</h3>
        <p>
          Your use of our Services is also governed by our Privacy Policy, which is incorporated into these Terms by reference.
        </p>

        <h3>17. Governing Law</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.
        </p>

        <h3>18. Changes to the Terms</h3>
        <p>
          We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on our website. Your continued use of the Services constitutes acceptance of the revised Terms.
        </p>

        <h3>19. Contact Information</h3>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <ul>
          <li>Email: <a href="mailto:info@engravemyride.com">info@engravemyride.com</a></li>
        </ul>

        <p>
          <Link to="/privacy-policy" className="link">
            Read our Privacy Policy
          </Link>
        </p>
      </section>
    </div>
  );
}

export default Terms;
