import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Layout.css'; // Import the new CSS file
import logo from '../assets/logo.webp'; // Placeholder for your logo

function Layout({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="website-layout">
      <header className="site-header">
        <nav className="navigation">
          <div className="logo-container">
            <Link to="/">
              <img src={logo} alt="Engrave My Ride Logo" className="logo" />
            </Link>
          </div>
          <button className="hamburger-menu" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
            <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li><Link to="/upload" onClick={toggleMenu}>Create Your Own</Link></li>
            <li><Link to="/gallery" onClick={toggleMenu}>Gallery</Link></li>
            <li><Link to="/about" onClick={toggleMenu}>About Us</Link></li>
            <li><Link to="/faq" onClick={toggleMenu}>FAQ</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
          </ul>
        </nav>
      </header>
      <main>{children}</main>
      <footer className="site-footer">
        <p>&copy; 2024 Engrave My Ride. All rights reserved.</p>
        <p>
          <Link to="/privacy">Privacy Policy</Link> | 
          <Link to="/terms">Terms and Conditions</Link>
        </p>
        <p>
          <Link to="#" onClick={() => {
            document.cookie = "engravemyrideCookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.reload();
          }}>Reset Cookie Settings</Link>
        </p>
      </footer>
    </div>
  );
}

export default Layout;
