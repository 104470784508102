import React from 'react';
import '../styles/Success.css';

const Success = () => {
  return (
    <div className="success-container">
      <div className="success-content">
        <h1>Thank you for your order!</h1>
        <p>Your order has been placed successfully. We will process it shortly.</p>
        <p>You will receive an email with the order details and a tracking number once it's shipped.</p>
        <p>Thank you for your purchase!</p>
        <a href="/" className="success-button">Return to Home</a>
      </div>
    </div>
  );
};

export default Success;