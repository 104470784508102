import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Approve.css';

const DISPLAY_URL = `${process.env.REACT_APP_BACKEND_URL}/display`;

function Approve() {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  const uuid = localStorage.getItem('uuid');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(DISPLAY_URL, {
          params: { uuid },
          responseType: 'blob'
        });
        if (response.status === 200) {
          const imageBlob = new Blob([response.data]);
          const imageUrl = URL.createObjectURL(imageBlob);
          setImage(imageUrl);
        } else {
          alert('Display failed.');
        }
      } catch (error) {
        alert('Display failed.');
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [uuid]);

  const handleApprove = () => {
    history('/edit_output');
  };

  const handleReject = () => {
    localStorage.removeItem('uuid');
    history('/');
  };

  return (
    <div className="approve-container">
      <h1>Please check the result</h1>
      <p>If the background removal was not performed correctly please reject and try another image.</p>
      {loading ? (
        <p className="loading-message">Processing the image...</p>
      ) : (
        <div>
          <div className="image-container">
            <img src={image} alt="Processed" />
          </div>
          <div className="button-container">
            <button className="approve-button" onClick={handleApprove}>Approve</button>
            <button className="reject-button" onClick={handleReject}>Reject</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Approve;