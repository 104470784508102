import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import '../styles/EditOutput.css';
import TextPositionControl from './TextPositionControl';
import ProgressBar from './ProgressBar';

const PROCESS_IMAGE_URL = `${process.env.REACT_APP_BACKEND_URL}/make_dxf_image`;
const CHECKOUT_URL = `${process.env.REACT_APP_BACKEND_URL}/create_checkout_session`;
const COUPON_CHECK_URL = `${process.env.REACT_APP_BACKEND_URL}/check_coupon_codes`;

const fontsDict = {
  ferrari: { name: 'Ferrari', file: 'ferrari.ttf' },
  porsche: { name: 'Porsche', file: 'porsche.ttf' },
  lambo: { name: 'Lambo', file: 'lambo.ttf' },
  honda: { name: 'Honda', file: 'honda.ttf' },
  ford: { name: 'Ford', file: 'ford.ttf' },
  mercedes: { name: 'Mercedes', file: 'mercedes.ttf' },
};

function EditOutput() {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [latestImage, setLatestImage] = useState(null);
  const [textSvg, setTextSvg] = useState(null);
  const [localTextOffset, setLocalTextOffset] = useState({ x: 0, y: 0 });
  const svgWrapperRef = useRef(null);
  const [svgWrapperHeight, setSvgWrapperHeight] = useState(null);
  const [price, setPrice] = useState(20);
  const [couponCode, setCouponCode] = useState('');
  const [basePrice, setBasePrice] = useState(20);
  const [discountInfo, setDiscountInfo] = useState(null);
  const [couponError, setCouponError] = useState('');
  const [formData, setFormData] = useState({
    position: 'right',
    blackValue: 5,
    printOption: 'Single side',
    textInput: 'Test',
    font: 'ferrari',
    textOffset: { x: 0, y: 0 },
  });
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  useEffect(() => {
    if (formData.printOption === 'Single side') {
      setBasePrice(20);
    } else {
      setBasePrice(35);
    }
  }, [formData.printOption]);

  const uuid = localStorage.getItem('uuid');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchImage = useCallback(
    debounce(async (payload) => {
      setLoading(true);
      try {
        const response = await axios.post(PROCESS_IMAGE_URL, payload, {
          withCredentials: true,
        });
        if (response.status === 200) {
          setLatestImage(response.data['svg_string']);
          setTextSvg(response.data['svg_text']);
        } else {
          alert('Failed to process image.');
        }
      } catch (error) {
        alert('Failed to process image.');
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    const payload = {
      uuid,
      donutPosition: formData.position,
      blackValue: parseInt(formData.blackValue),
      printOption: formData.printOption,
      textInput: formData.textInput,
      font: formData.font,
      saveToS3: false,
    };


    debouncedFetchImage(payload);

    if (formData.printOption === 'Single side') {
      setPrice(20);
    } else {
      setPrice(35);
    }

    // Cleanup function to cancel any pending debounced calls
    return () => debouncedFetchImage.cancel();
  }, [formData, uuid, debouncedFetchImage, step, formData.printOption]);

  useEffect(() => {
    if (svgWrapperRef.current) {
      const height = svgWrapperRef.current.offsetHeight;
      if (height > 0) {
        setSvgWrapperHeight(height);
      }
    }
  }, [latestImage]);

  useEffect(() => {
    // Add gtag function to window object
    window.gtag_report_conversion = function(url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16540160046/38kzCO2Y6t4ZEK6g-849',
        // 'value': price,
        // 'currency': 'EUR',
        'transaction_id': '', //uuid,
        'event_callback': callback
      });
      return false;
    };
  }, []); //price, uuid

  const moveText = (direction) => {
    const stepSize = 5; // Adjust this value to control the speed of movement
    const isVertical = formData.position === 'up' || formData.position === 'down';
    const multiplier = isVertical ? -1 : 1;
    setLocalTextOffset((prevOffset) => {
      let newOffset;
      switch (direction) {
        case 'left':
          newOffset = { ...prevOffset, x: prevOffset.x - stepSize * multiplier };
          break;
        case 'right':
          newOffset = { ...prevOffset, x: prevOffset.x + stepSize * multiplier };
          break;
        case 'up':
          newOffset = { ...prevOffset, y: prevOffset.y - stepSize * multiplier };
          break;
        case 'down':
          newOffset = { ...prevOffset, y: prevOffset.y + stepSize * multiplier };
          break;
        default:
          newOffset = prevOffset;
      }
      return newOffset;
    });
  };

  const renderSVG = () => {
    if (!latestImage) return null;

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(latestImage, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    // Remove existing width and height attributes
    svgElement.removeAttribute('width');
    svgElement.removeAttribute('height');

    // Add preserveAspectRatio attribute
    svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

    // Add responsive sizing
    svgElement.setAttribute('width', '100%');
    svgElement.setAttribute('height', '100%');

    // Rotate SVG if necessary
    if (formData.position === 'up' || formData.position === 'down') {
      svgElement.setAttribute('transform', 'rotate(180)');
    }

    // Add text SVG if available
    if (textSvg) {
      const textElement = parser.parseFromString(textSvg, 'image/svg+xml').documentElement;
      const textGroup = textElement.querySelector('g');
      const currentTransform = textGroup.getAttribute('transform');
      // Remove existing width and height attributes
      textElement.removeAttribute('width');
      textElement.removeAttribute('height');

      textElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

      textElement.setAttribute('width', '100%');
      textElement.setAttribute('height', '100%');


      // // Apply same transformations to textSvg
      // if (formData.position === 'up' || formData.position === 'down') {
      //   textElement.setAttribute('transform', 'rotate(180)');
      // }

      // Apply the additional offset to the text
      const totalOffset = {
        x: formData.textOffset.x + localTextOffset.x,
        y: formData.textOffset.y + localTextOffset.y,
      };
      const newTransform = `${currentTransform} translate(${totalOffset.x}, ${totalOffset.y})`;
      textGroup.setAttribute('transform', newTransform);

      // Append the text SVG to the main SVG
      svgElement.appendChild(textElement);
    }

    return svgElement.outerHTML;
  };

  const handleCheckout = async () => {
    try {
      const response = await axios.post(
        CHECKOUT_URL,
        { uuid , 
          printOption: formData.printOption,
          couponCode: couponCode.trim() || undefined
        },
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      if (response.data && response.data.url) {
        // Call gtag function with the checkout URL
        window.gtag_report_conversion(response.data.url);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Checkout error:', error);
      alert('Failed to initiate checkout. Please try again.');
    }
  };

  const checkCouponCode = async (code) => {
    if (!code.trim()) {
      setDiscountInfo(null);
      setCouponError('');
      setIsCouponApplied(false);
      return;
    }

    try {
      const response = await axios.post(COUPON_CHECK_URL, { couponCode: code });
      if (response.status === 200) {
        setDiscountInfo(response.data);
        setIsCouponApplied(true);
        setCouponError('');
      }
    } catch (error) {
      setDiscountInfo(null);
      setCouponError('Invalid coupon code');
      setIsCouponApplied(false);
    }
  };

  const calculateFinalPrice = () => {
    if (!discountInfo) return basePrice;

    if (discountInfo.percent_off) {
      console.log(discountInfo.percent_off);

      return basePrice * (1 - discountInfo.percent_off / 100);
    }
    if (discountInfo.amount_off) {
      return Math.max(basePrice - (discountInfo.amount_off / 100), 0);
    }
    return basePrice;
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="step-content">
            <h2>Step 1: Select Contrast</h2>
            <label>Adjust the contrast:</label>
            <div className="contrast-banner">
              <span>Darker</span>
              <span>Lighter</span>
            </div>
            <input
              type="range"
              min="1"
              max="12"
              value={formData.blackValue}
              onChange={(e) => setFormData({ ...formData, blackValue: e.target.value })}
            />
            <button onClick={() => setStep(2)}>Next</button>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <h2>Step 2: Select Print Option and Position</h2>
            <label>Print Option:</label>
            <select
              value={formData.printOption}
              onChange={(e) => setFormData({ ...formData, printOption: e.target.value })}
            >
              <option value="Single side">Single side (€20)</option>
              <option value="Double engraving">Double engraving (€35)</option>
              <option value="Text engraving">Text engraving (€35)</option>
            </select>
            <label>Position:</label>
            <select
              value={formData.position}
              onChange={(e) => setFormData({ ...formData, position: e.target.value })}
            >
              <option value="right">Right</option>
              <option value="left">Left</option>
              <option value="up">Top</option>
              <option value="down">Bottom</option>
            </select>
            <div className="price-display">
              Current Price: €{price}
            </div>
            <button onClick={() => setStep(formData.printOption === 'Text engraving' ? 3 : 4)}>
              Next
            </button>
          </div>
        );
        case 3:
          return (
            <div className="step-content">
              <h2>Step 3: Enter Text and Position</h2>
              <label>Enter your text:</label>
              <input
                type="text"
                value={formData.textInput}
                onChange={(e) => setFormData({ ...formData, textInput: e.target.value })}
              />
              <label>Select Font:</label>
              <select
                value={formData.font}
                onChange={(e) => setFormData({ ...formData, font: e.target.value })}
              >
                {Object.entries(fontsDict).map(([key, { name }]) => (
                  <option key={key} value={key} className={`font-${key}`}>
                    {name}
                  </option>
                ))}
              </select>
              <TextPositionControl onMove={moveText} />
              <div className="price-display">
                Current Price: €{price}
              </div>
              <button onClick={handleStep3Next}>Next</button>
            </div>
          );
          case 4:
            return (
              <div className="step-content">
                <h2>Step 4: Review and Checkout</h2>
                <p>
                  Please review your design. If you're satisfied, click the Checkout button to proceed.
                </p>
                <div className="coupon-input">
                  <label>Have a coupon code?</label>
                  <div className="coupon-input-group">
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                        // Reset coupon state when user types
                        if (isCouponApplied) {
                          setIsCouponApplied(false);
                          setDiscountInfo(null);
                          setCouponError('');
                        }
                      }}
                      placeholder="Enter coupon code (optional)"
                    />
                    <button 
                      onClick={() => checkCouponCode(couponCode)}
                      disabled={!couponCode.trim() || isCouponApplied}
                    >
                      Apply
                    </button>
                  </div>
                  {couponError && <div className="coupon-error">{couponError}</div>}
                  {discountInfo && (
                    <div className="coupon-success">
                      Coupon applied successfully!
                    </div>
                  )}
                </div>
                <div className="price-display">
                  {discountInfo && (
                    <div className="original-price"><s>Original Price: €{basePrice}</s></div>
                  )}
                  Current Price: €{calculateFinalPrice().toFixed(2)}
                </div>
                <button onClick={handleStep4Checkout}>Checkout</button>
              </div>
            );
      default:
        return null;
    }
  };

  const handleStep3Next = () => {
    // Update formData with the final text offset before moving to the next step
    setFormData((prevFormData) => ({
      ...prevFormData,
      textOffset: {
        x: prevFormData.textOffset.x + localTextOffset.x,
        y: prevFormData.textOffset.y + localTextOffset.y,
      },
    }));
    setLocalTextOffset({ x: 0, y: 0 }); // Reset local offset
    setStep(4);
  };

  const handleStep4Checkout = async () => {
    console.log(discountInfo);
    const payload = {
      uuid,
      donutPosition: formData.position,
      blackValue: parseInt(formData.blackValue),
      printOption: formData.printOption,
      textInput: formData.textInput,
      font: formData.font,
      textOffset: formData.textOffset,
      saveToS3: true,
      couponId: discountInfo?.id // Add coupon ID if available
    };
    setLoading(true);
    try {
      const response = await axios.post(PROCESS_IMAGE_URL, payload);
      if (response.status === 200) {
        handleCheckout();
      } else {
        alert('Failed to process and save image.');
      }
    } catch (error) {
      console.error('Error processing image:', error);
      alert('Failed to process and save image.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="edit-output-container">
      <div className="content-wrapper">
        <ProgressBar currentStep={step} totalSteps={4} />
        <div className="image-preview">
          <div className="svg-wrapper" ref={svgWrapperRef}>
            {!loading && latestImage && (
              <div dangerouslySetInnerHTML={{ __html: renderSVG() }} />
            )}
            {loading && (
              <div
                className="loading-placeholder"
                style={{ height: svgWrapperHeight ? `${svgWrapperHeight}px` : 'auto' }}
              >
                Loading...
              </div>
            )}
          </div>
        </div>
        <div className="step-wrapper">{renderStepContent()}</div>
      </div>
    </div>
  );
}

export default EditOutput;
