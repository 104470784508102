import React from 'react';
import '../styles/Gallery.css';
import { InstagramEmbed } from 'react-social-media-embed';

const Gallery = () => {
  return (
    <div className="Gallery">
      <div className="hero">
        <h1>Gallery</h1>
        <h2>Check out our latest work</h2>
      </div>

      <section className="gallery-list">
        <div className="gallery-item">
          <InstagramEmbed url="https://www.instagram.com/p/C6WIFOjsyn6/" width={328} />
        </div>
        <div className="gallery-item">
          <InstagramEmbed url="https://www.instagram.com/p/C6Yd6QHM4kT/" width={328} />
        </div>
        <div className="gallery-item">
          <InstagramEmbed url="https://www.instagram.com/p/C56NAgXo7za/" width={328} />
        </div>
      </section>
    </div>
  );
};

export default Gallery;