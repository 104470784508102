import React from 'react';
import '../styles/PrivacyPolicy.css';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <section className="hero">
        <h1>Privacy Policy</h1>
        <h2>Your Privacy Matters to Us</h2>
      </section>

      <section className="content">
        <h3>1. Introduction</h3>
        <p>
          Engrave My Ride ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website and Services.
        </p>

        <h3>2. Information We Collect</h3>
        <h4>2.1. Personal Information</h4>
        <p>
          We may collect personally identifiable information ("Personal Information") that you voluntarily provide to us when you:
        </p>
        <ul>
          <li>Create an account.</li>
          <li>Place an order.</li>
          <li>Upload images or content.</li>
          <li>Subscribe to newsletters.</li>
          <li>Contact customer support.</li>
        </ul>
        <p>Personal Information may include:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Mailing address</li>
          <li>Phone number</li>
          <li>Payment information</li>
        </ul>

        <h4>2.2. Usage Data</h4>
        <p>
          We may collect non-personal information about how the Services are accessed and used ("Usage Data"), such as:
        </p>
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Pages visited</li>
          <li>Time and date of visits</li>
          <li>Device information</li>
        </ul>

        <h4>2.3. Cookies and Similar Technologies</h4>
        <p>
          We use cookies and similar tracking technologies to enhance your experience, analyze usage, and for marketing purposes.
        </p>

        <h3>3. How We Use Your Information</h3>
        <p>
          We may use your information to:
        </p>
        <ul>
          <li>Provide and maintain our Services.</li>
          <li>Process and fulfill your orders.</li>
          <li>Communicate with you about your account or transactions.</li>
          <li>Send promotional materials with your consent.</li>
          <li>Improve our website and Services.</li>
          <li>Detect and prevent fraud.</li>
        </ul>

        <h3>4. Sharing Your Information</h3>
        <h4>4.1. Service Providers</h4>
        <p>
          Third-party companies and individuals that facilitate our Services, such as payment processors and shipping companies.
        </p>
        <h4>4.2. Legal Requirements</h4>
        <p>
          We may disclose your information if required to do so by law or in response to valid requests by public authorities.
        </p>
        <h4>4.3. Business Transfers</h4>
        <p>
          In the event of a merger, acquisition, or asset sale, your Personal Information may be transferred.
        </p>

        <h3>5. Data Security</h3>
        <p>
          We implement reasonable security measures to protect your Personal Information. However, no method of transmission over the Internet or electronic storage is 100% secure.
        </p>

        <h3>6. Data Retention</h3>
        <p>
          We will retain your Personal Information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy.
        </p>

        <h3>7. Your Rights and Choices</h3>
        <h4>7.1. Access and Correction</h4>
        <p>
          You may access, correct, or update your Personal Information by logging into your account or contacting us.
        </p>
        <h4>7.2. Opt-Out</h4>
        <p>
          <strong>Marketing Communications:</strong> You may opt out of receiving promotional emails by following the unsubscribe instructions.<br />
          <strong>Cookies:</strong> You can set your browser to refuse all or some browser cookies.
        </p>
        <h4>7.3. Deletion</h4>
        <p>
          You may request that we delete your Personal Information. We will comply unless we are required to retain it for legal reasons.
        </p>

        <h3>8. Children's Privacy</h3>
        <p>
          Our Services are not intended for individuals under the age of 13. We do not knowingly collect Personal Information from children under 13.
        </p>

        <h3>9. International Data Transfers</h3>
        <p>
          Your information may be transferred to and maintained on servers located outside of your state, province, country, or other governmental jurisdiction.
        </p>

        <h3>10. Third-Party Links</h3>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices of those sites.
        </p>

        <h3>11. Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. Changes will be posted on this page with an updated revision date.
        </p>

        <h3>12. Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <ul>
          <li>Email: <a href="mailto:your-email@example.com">your-email@example.com</a></li>
          <li>Address: Your physical address</li>
          <li>Phone: Your phone number</li>
        </ul>

        <p>
          <Link to="/terms" className="link">
            Read our Terms and Conditions
          </Link>
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
