import React from 'react';
import '../styles/ProgressBar.css';

function ProgressBar({ currentStep, totalSteps }) {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);
  const stepLabels = [
    'Adjust Contrast',
    'Choose Design',
    'Add Text',
    'Review & Checkout',
  ]; // Labels for each step

  return (
    <div className="progress-bar">
      {steps.map((step, index) => (
        <div key={step} className="progress-step">
          <div className={`step-circle ${currentStep >= step ? 'active' : ''}`}>
            <span className="step-number">{step}</span>
          </div>
          {index < totalSteps - 1 && (
            <div className={`step-line ${currentStep > step ? 'active' : ''}`}></div>
          )}
          <div className="step-label">{stepLabels[index]}</div>
        </div>
      ))}
    </div>
  );
}

export default ProgressBar;
