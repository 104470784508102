import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/About.css';

function About() {
  return (
    <div className="about">
      <section className="hero">
        <h1>About Us</h1>
        <h2>Welcome to EngraveMyRide, Where Every Ride Becomes a Memory!</h2>
      </section>

      <section className="founder">
        <h3>Our Story</h3>
        <p>
          Hello! I'm Tumsa, the founder of EngraveMyRide. As a lifelong motorcycle enthusiast and 
          professional in computer vision, I've always sought ways to combine my passions.
        </p>
        <p>
          Driven by this curiosity, I delved deep into the world of computer vision. My goal was to 
          develop a tool that not only recognized the fine details of a photo but could translate 
          these into artistic, tangible gadgets.
        </p>
        <p>
          Here at EngraveMyRide, we're dedicated to transforming your beloved motorcycle or car into 
          a beautifully crafted keychain that reflects all the specific features and modifications of your ride.
        </p>
      </section>

      <section className="how-it-works">
        <h3>How It Works</h3>
        <h4>In 5 Simple Steps to Your Custom Keychain</h4>
        <ol>
          <li><strong>Upload:</strong> Choose an image to upload to our tool</li>
          <li><strong>Approve:</strong> Review the result of our background removal. Approve the image or try again with a new image</li>
          <li><strong>Customize:</strong> Adjust the slider to choose a contrast setting that gives you the best result. Select the location of the eye where the keychain will hang.</li>
          <li><strong>Checkout:</strong> Easily pay with our third-party payment provider Stripe.</li>
          <li><strong>Shipping:</strong> Allow up to 2 weeks for us to produce and deliver your keychain.</li>
        </ol>
        <Link to="/upload" className="cta-button">Start Designing</Link>
      </section>

      <section className="technology">
        <h3>Technology Behind the Craft</h3>
        <h4>Precision with a Personal Touch</h4>
        <p>
          At EngraveMyRide, we harness the power of advanced computer vision to ensure that every 
          detail from your photo is perfectly translated into your custom keychain. Our algorithm 
          focuses on distinctive features such as special modifications and unique details, ensuring 
          that each keychain is a true reflection of your vehicle.
        </p>
      </section>

      <section className="stay-connected">
        <h3>Stay Connected</h3>
        <p>
          Stay up to date with new features, special offers, and the cool vehicles we're turning 
          into keychains by following us on Instagram.
        </p>
        
        {/* Add Instagram embed or link here */}
      </section>
    </div>
  );
}

export default About;